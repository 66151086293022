@import "../styles/breakpoints";

.credits{
    &__grid-wrapper{
        padding: calc(16vh + 50px) 20%;
        text-align: center;
    }
}

@include mobile{

    .credits{

        &__grid-wrapper{
            padding: 180px 20px;
        }
    }
}