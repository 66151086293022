@import "../styles/breakpoints";

.zine{
    &__grid-wrapper{
        padding: calc(16vh + 20px) 5%;
    }

    &__item-wrapper{
        margin-bottom: 20px;
        width: 100%;
        height: calc(var(--app-height) - 120px);
        text-align: center;
    }
    &__item-image{
        display: block;
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
        margin: 0 auto;
    }
}

@include mobile{

    .zine{

        &__grid-wrapper{
            padding: 180px 20px;
            display: block;
        }
    
        &__item-wrapper{
            margin: 0 0 20px 0;
            width: 100%;
            height: auto;
        }
    }
}