

*::selection {
  background: #FFF;
  color: #000;
}
*::-moz-selection {
  background: #FFF;
  color: #000;
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: #FFF;
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}


html,
body {
  width: 100vw;
  height: var(--app-height);
  // color: #FFF;
  color: var(--pink);
  text-transform: uppercase;
  background-image: url("../assets/backdrop.png");
  background-size: cover;
}

.header{
  position: fixed;
  margin: 20px 10px;
  top: 0;
  left: 0;
  z-index: 100;
}

.link{
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 30px;
  margin:5px 10px;
  display: inline-block;
  @include hover{
    background-color: #000;
    color: #FFF;
  }
}

.default-button{
  padding: 10px 20px;
  text-align: center;
  color: var(--pink);
  background-color: #000;
  border: 1px solid var(--pink);
  font-family: "Sans-2", sans-serif;
  border-radius: 30px;
  cursor: pointer;
  @include hover{
    background-color: var(--pink);
    color: #000;
  }
}

.loading-notifier{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  animation: pulse 0.5s linear infinite;
}

.home__canvas{
  cursor: grab;
  &:active{
    cursor: grabbing;
  }
}

.outer-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--app-height);
  background-color: #FFF;
  z-index: 5;
  overflow-y: auto;
}

.back-button{
  margin: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

@include mobile{
  .back-button{
    margin: 0 ;
    top: auto;
    bottom: 90px;
    left: 20px;
  }
}