@font-face {
  font-family: "Sans-1";
  src: url("../assets/fonts/Sans-1.eot");
  src: url("../assets/fonts/Sans-1.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Sans-1.woff2") format("woff2"),
    url("../assets/fonts/Sans-1.woff") format("woff"),
    url("../assets/fonts/Sans-1.ttf") format("truetype"),
    url("../assets/fonts/Sans-1.svg#Sans-1") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sans-2";
  src: url("../assets/fonts/Sans-2.eot");
  src: url("../assets/fonts/Sans-2.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Sans-2.woff2") format("woff2"),
    url("../assets/fonts/Sans-2.woff") format("woff"),
    url("../assets/fonts/Sans-2.ttf") format("truetype"),
    url("../assets/fonts/Sans-2.svg#Sans-2") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  font-family: "Sans-2", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.preloader__title {
  font-size: 1.6vw;
}

.credits__grid-wrapper {
  font-size: 1.6vw;
  line-height: 1.6vw;
  h1 {
    font-size: 3vw;
    line-height: 3vw;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 3vw;
    line-height: 3vw;
  }

  h3{
    font-size: 1.6vw;
    line-height: 1.6vw;
  }

  p {
    margin-bottom: 50px;
  }
}
@include mobile {
  html,
  body {
    font-size: 8px;
    line-height: 8px;
  }
  .preloader__title {
    font-size: 14px;
  }
  .credits__grid-wrapper {
    font-size: 14px;
    line-height: 18px;
    h1 {
      font-size: 20px;
      line-height: 20px;
    }

    h2 {
      font-size: 24px;
      line-height: 26px;
    }
  
    h3{
      font-size: 14px;
      line-height: 16px;
    }
  }
}
