@import "../../styles/helpers";
@import "../../styles/breakpoints";

.preloader{

    &__wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
        pointer-events: all;
        text-align: center;
        transition: opacity 0.4s ease-in-out;
        &.disabled{
            opacity: 0;
            pointer-events: none;
        }
    }

    &__inner{
        width: 20%;
        margin: 0 auto;
        position: relative;
        top: -50px;
    }

    &__logo{
        display: block;
        width: 200%;
        position: relative;
        top: 50px;
        left: -50%;
        height: auto;
        // margin-bottom: 20px;
    }

    &__title{
        margin-bottom: 20px;
    }

    &__load-outer{
        width: calc(100% - 2px);
        height: 30px;
        text-align: center;
        border: 1px solid #FFF;
        position: relative;
        &.disabled{
            display: none;
        }
    }

    &__load-inner{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #FFF;
        height: 100%;
        // width: 50%;
    }

    &__load-text{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        mix-blend-mode: difference;
        color: #FFF;
    }

    &__enter-button{
        width: 100%;
        height: 30px;
        text-align: center;
        border: 1px solid #FFF;
        position: relative;
        display: none;
        cursor: pointer;
        background-color: #FFF;
        color: #000;
        &.active{
            display: block;
        }
        @include hover{
            background-color: #000;
            color: #FFF;
        }
    }

}

@include mobile{
    .preloader{

    
        &__logo{
            // display: block;
            width: 100%;
            // position: relative;
            top: 20px;
            left: 0;
            // height: auto;
            // // margin-bottom: 20px;
        }
        &__inner{
            width: 80%;
            margin: 0 auto;
        }
    
    }

}