@import "../../styles/breakpoints";

.header{

    &__logo{
        // width: auto;
        // height: 15vh;
        width: 30vw;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 10px;
        z-index: 999;
        pointer-events: none;
    }

    &__mute{
        margin: 20px;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 999;
    }

    &__nav-outer{
        // width: 60%;
        position: fixed;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid var(--pink);
        padding: 10px;
        border-radius: 50px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 999;
    }

    &__nav-item{
        margin: 0 20px;
    }

    &__prev,
    &__next{
        position: fixed;
        bottom: 110px;
        z-index: 4;
        &.disabled{
            pointer-events: none;
            opacity: 0.8;
        }
    }

    &__prev{
        left: calc(50% - 255px);
    }
    &__next{
        right: calc(50% - 255px);
    }

    &__click{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
        transition: opacity 0.4s ease-in-out;
        pointer-events: none;
        &.disabled{
            opacity: 0;
        }
    }

}

@include mobile{

    .header{

        &__logo{
            width: calc(100% - 40px);
            height: auto;
            top: 5px;
            margin: 0 20px;
            left: auto;
            transform: none;
        }
    
        &__mute{
            margin: 0;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            top: auto;
            bottom: 90px;
        }
    
        &__nav-outer{
            width: calc(100% - 40px);
            padding: 5px 10px;
            border-radius: 50px;
            background-color: #000;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            z-index: 999;
        }
    
        &__nav-item{
            margin: 5px;
        }

        &__prev,
        &__next{
            bottom: 90px;
        }

        &__prev{
            left: 20px;
        }
        &__next{
            right: 20px;
        }
    
    }

}