@import "../styles/helpers";
@import "../styles/breakpoints";

.looks{

    &__grid-wrapper{
        padding: calc(16vh + 20px) 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &__item-wrapper{
        margin: 20px;
        width: calc(33.33% - 42px);
        // height: calc(var(--app-height) - 120px);
        height: auto;
        border: 1px solid var(--pink);
        border-radius: 15px;
        text-align: center;
    }

    &__item-image{
        display: block;
        width: 100%;
        height: auto;
    }

    &__item-title{
        width: 100%;
        margin: 20px auto;
    }

}

@include mobile{

    .looks{

        &__grid-wrapper{
            padding: 110px 20px;
            display: block;
        }
    
        &__item-wrapper{
            margin: 0 0 20px 0;
            width: 100%;
            height: auto;
            // height: calc(var(--app-height) - 180px);
        }
    
    }
}